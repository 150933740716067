// import React, { useState } from "react";
// import MealPrep from "../images/MealPrep.svg";
// import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
// import { Link } from "react-router-dom";
// import Profile from "../images/user.png";
// import Cart from "../images/shopping-cart.png";
// import Line from "../images/Line50.png";
// import { AuthState } from "../context/authContext";
// import ThreeDotdropdown from "./ThreeDotdropdown.js";
// import Logout from "./Logout.js";


// const NavBar = () => {
//   const [navEnabled, setNavEnabled] = useState(false);
//   const { user } = AuthState();

//   return (
//     <nav className="container px-5 mx-auto flex justify-between items-center h-24 text-black ">
//       <Link to="/" className="relative">
//         <img alt="MealPrep" src={MealPrep} />
//       </Link>

//       <ul className="hidden xl:flex gap-12">
//         <li>
//           <Link className="link font-semibold" to={"/weeklyMenu/0"}>
//             WEEKLY MENU
//           </Link>
//         </li>
//         <li>
//           <Link className="link font-semibold" to={"/steps"}>
//             OUR PLANS
//           </Link>
//         </li>
//         <li>
//           <Link className="link font-semibold" to={"/faqs"}>
//             FAQS
//           </Link>
//         </li>
//         {/* <li>
//           <Link className="link font-semibold" to={"/WeeklyMenu"}>
//             GIFT CARDS
//           </Link>
//         </li> */}
//       </ul>

//       <div className="hidden xl:flex gap-4 items-center">
//         {!user && (
//           <Link className="link font-semibold" to={"/login"}>
//             LOGIN
//           </Link>
//         )}

//         {/* <Link
//           className="hidden btn-base px-6 xl:flex items-center"
//           to={"/steps"}
//         >
//           Get Started
//         </Link> */}

//         {!!user && (
//           <>
//             <ThreeDotdropdown />
//             {/* <img className="" src={Line} alt="line" />

//             <Link
//               className=""
//               to={"/profile"}
//               onClick={() => setNavEnabled(false)}
//             >
//               <img src={Profile} alt="Profile/" />
//             </Link>

//             <img className="" src={Line} alt="line" />

//             <Link
//               className=""
//               to={"/orders"}
//               onClick={() => setNavEnabled(false)}
//             >
//               <img src={Cart} alt="ShoppingCart" />
//             </Link> */}
//           </>
//         )}
//       </div>

//       <div className="block xl:hidden">
//         <AiOutlineMenu
//           className="cursor-pointer"
//           size={24}
//           onClick={() => {
//             setNavEnabled(true);
//           }}
//         />
//       </div>

//       {/* mobile menu */}
//       <div
//         className={`fixed left-0 top-0 w-full h-full bg-white z-50 transition-transform ${navEnabled ? "translate-x-0" : "translate-x-[-100vw]"
//           } flex flex-col px-4 gap-6`}
//       >
//         <div className="flex justify-between items-center h-24">
//           <Link to="/">
//             <img alt="MealPrep" src={MealPrep} />
//           </Link>
//           <AiOutlineClose
//             className="cursor-pointer"
//             size={24}
//             onClick={() => {
//               setNavEnabled(false);
//             }}
//           />
//         </div>
//         <ul className="flex flex-col items-start gap-4">
//           <li>
//             <Link
//               className="link font-semibold text-xl"
//               to={"/WeeklyMenu/0"}
//               onClick={() => setNavEnabled(false)}
//             >
//               WEEKLY MENU
//             </Link>
//           </li>
//           <li>
//             <Link
//               className="link font-semibold text-xl"
//               to={"/WeeklyMenu"}
//               onClick={() => setNavEnabled(false)}
//             >
//               OUR PLANS
//             </Link>
//           </li>
//           <li>
//             <Link
//               className="link font-semibold text-xl"
//               to={"/FAQS"}
//               onClick={() => setNavEnabled(false)}
//             >
//               FAQS
//             </Link>
//           </li>
//           <li>
//             <Link
//               className="link font-semibold text-xl"
//               to={"/profile"}
//               onClick={() => setNavEnabled(false)}
//             >
//               MY PROFILE
//             </Link>
//           </li>
//           <li>
//             {!user && (
//               <Link className="link font-semibold text-xl" to={"/login"}>
//                 LOGIN
//               </Link>
//             ) || (<Logout/>)}
//           </li>
//           {/* <li>
//             <Link
//               className=""
//               to={"/profile"}
//               onClick={() => setNavEnabled(false)}
//             >
//               <img src={Profile} alt="Profile/" />
//             </Link>
//           </li>
//           <li>
//             <Link
//               className=""
//               to={"/orders"}
//               onClick={() => setNavEnabled(false)}
//             >
//               <img src={Cart} alt="ShoppingCart" />
//             </Link>
//           </li> */}
//         </ul>
//       </div>
//     </nav>
//   );
// };

// export default NavBar;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { AuthState } from "../context/authContext";
import ThreeDotdropdown from "./ThreeDotdropdown";
import Logout from "./Logout";

import MealPrep from "../images/MealPrep.svg";

const NavBar = () => {
  const [navEnabled, setNavEnabled] = useState(false);
  const { user } = AuthState();

  return (
    <nav className="container px-5 mx-auto flex justify-between items-center h-24 text-black">
      <Link to="/" className="relative">
        <img alt="MealPrep logo" src={MealPrep} />
      </Link>

      {/* Desktop Menu */}
      <ul className="hidden xl:flex gap-12">
        <li><Link className="link font-semibold" to="/weeklyMenu/0">WEEKLY MENU</Link></li>
        <li><Link className="link font-semibold" to="/steps">OUR PLANS</Link></li>
        <li><Link className="link font-semibold" to="/faqs">FAQS</Link></li>
      </ul>

      <div className="hidden xl:flex gap-4 items-center">
        {!user ? (
          <Link className="link font-semibold" to="/login">LOGIN</Link>
        ) : (
          <>
            <ThreeDotdropdown />
          </>
        )}
      </div>

      {/* Mobile Menu Toggle */}
      <div className="xl:hidden">
        <AiOutlineMenu
          className="cursor-pointer"
          size={24}
          onClick={() => setNavEnabled(!navEnabled)}
        />
      </div>

      {/* Mobile Menu */}
      <div className={`fixed left-0 top-0 w-full h-full bg-white z-50 transition-transform ${navEnabled ? "translate-x-0" : "translate-x-[-100vw]"} flex flex-col p-4`}>
        <div className="flex justify-between items-center h-24">
          <Link to="/" onClick={() => setNavEnabled(false)}>
            <img alt="MealPrep logo" src={MealPrep} />
          </Link>
          <AiOutlineClose
            className="cursor-pointer"
            size={24}
            onClick={() => setNavEnabled(false)}
          />
        </div>
        <ul className="flex flex-col items-start gap-4">
          <li><Link className="link font-semibold text-xl" to="/weeklyMenu/0" onClick={() => setNavEnabled(false)}>WEEKLY MENU</Link></li>
          <li><Link className="link font-semibold text-xl" to="/steps" onClick={() => setNavEnabled(false)}>OUR PLANS</Link></li>
          <li><Link className="link font-semibold text-xl" to="/faqs" onClick={() => setNavEnabled(false)}>FAQS</Link></li>
          <li><Link className="link font-semibold text-xl" to="/profile" onClick={() => setNavEnabled(false)}>MY PROFILE</Link></li>
          <li>
            {user ? (
              <Logout />
            ) : (
              <Link className="link font-semibold text-xl" to="/login" onClick={() => setNavEnabled(false)}>LOGIN</Link>
            )}
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
