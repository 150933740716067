import React, { useState } from "react";
import MealPrep from "../images/MealPrep.svg";
import {Link, useNavigate} from "react-router-dom";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");


  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      return false;
    }

    setError('');
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateEmail()) {
      setSuccessMessage('Email submitted successfully!');


      const formData = new FormData(event.target);
      const data = {
        email: formData.get('email'),
      };

      //give the server URL in ''
      fetch('', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          console.log('Success:', data);
          // Handle the response from the server here
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle the error here
        });
    } else {
      setSuccessMessage("");
    }
  };

  const navigate = useNavigate();

  return (
    <footer>
      {/* cta */}
      <div className="bg-redlight py-10 px-4 xl:px-0">
        <div className="container mx-auto flex flex-col xl:flex-row xl:justify-between items-start xl:items-center gap-6 px-5">
          <div className="text-white text-left flex flex-col gap-2">
            <p className="font-bold text-2xl">
              Ready-Made Meals Starting at $12 per meal
            </p>
            <p className="text-xs xl:text-sm">
            Delicious, chef-prepared dishes delivered to your door. No obligation required - feel free to skip or cancel anytime.
            </p>
          </div>
          <button onClick={()=>navigate('/Steps')} className="btn-white-xl text-center justify-items-center"><p>Order Now</p></button>
        </div>
      </div>
      <div className="xl:container mx-4 xl:mx-auto flex flex-col xl:flex-row xl:justify-between py-10 gap-10 items-start px-5">
        <div>
          <button onClick={()=>navigate('/')} className="">
            <img
              alt="MealPrep"
              src={MealPrep}
            />
          </button>
        </div>
        {/* footer links */}
        <div className="grid grid-rows-3 grid-flow-col gap-x-24 gap-y-2">
          <button onClick={()=>navigate('/WeeklyMenu')} className="link text-sm font-medium">Weekly Menu</button>
          <button onClick={()=>navigate('/steps')} className="link text-sm font-medium">Our Plans</button>
          <button onClick={()=>navigate('/faqs')} className="link text-sm font-medium">FAQs</button>
          {/* <button onClick={()=>navigate('')} className="link text-sm font-medium">Gift Cards</button> */}
          <button onClick={()=>navigate('/AboutUs')} className="link text-sm font-medium">About Us</button>
          <a href="https://sultanahmet.ca/contact/" className="link text-sm font-medium">Contact Us</a>
          <button onClick={()=>navigate('/terms&conditions')} className="link text-sm font-medium">Terms & Conditions</button>
        </div>
        <div>
          <p className="font-bold text-start text-uppercase text-lg xl:text-base mb-5 xl:mb-3">
          Sign up for exclusive offers, delicious updates, & more.
          </p>
          <form className="flex flex-col xl:flex-row gap-2" onSubmit={handleSubmit}>
            <div className="form-control w-full">
              <input
                className="border border-[#E3E3E3] rounded-lg text-xs h-10 w-full px-4"
                placeholder="Email Address"
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              {error && <div className="error-message">{error}</div>}
            </div>
            <button
              type="submit"
              className=" bg-black text-white rounded-lg px-8 py-2"
            >
              Submit
            </button>
            {successMessage && (
              <div className="success-message">{successMessage}</div>
            )}
          </form>
        </div>
      </div>
      <p className="text-xs font-medium text-grayText text-start xl:text-center my-5 xl:my-20 mx-4 xl:mx-0">
        © 2024 MealPrep. MealPrep is a registrated trademark of MealPrep, LLC.
        All Rights Reserved.
      </p>
    </footer>
  );
};

export default Footer;