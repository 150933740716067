import axios from "axios";
import { BASE_URL } from "../constants/settings";

export const findMeals = async (payload) => {
  try {
    const { page, limit, keywords } = payload;
    return Promise.resolve(
      await axios.get(
        `${BASE_URL}/api/v1/meals?page=${page}&limit=${limit}&keywords=${keywords}`
      )
    );
  } catch (err) {
    Promise.reject(err);
  }
};

export const findMealsPaginated = async (payload) => {
  try {
    const { page, limit, keywords, menuStartDate, menuEndDate } = payload;
    return Promise.resolve(
      await axios.get(
        `${BASE_URL}/api/v1/meals?page=${page}&limit=${limit}&keywords=${keywords}&menuStartDate=${menuStartDate}&menuEndDate=${menuEndDate}`
      )
    );
  } catch (err) {
    Promise.reject(err);
  }
};

export const filterByCategoriesMeals = async (query, limit) => {
  try {
    return Promise.resolve(
      await axios.get(`${BASE_URL}/api/v1/meals?page=1&limit=${limit}&${query}`)
    );
  } catch (err) {
    Promise.reject(err);
  }
};

export const findMealByID = async (id) => {
  try {
    return Promise.resolve(await axios.get(`${BASE_URL}/api/v1/meals/${id}`));
  } catch (err) {
    Promise.reject(err);
  }
};
