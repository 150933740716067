import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { validateEmail } from "../services/authService";

const registerSchema = yup.object({
  email: yup
    .string()
    .email("Email format is not valid")
    .required("Email is required"),
  password: yup
    .string()
    .min(5, "Password must be at least 5 characters")
    .required("Password is required"),
});

const Register = ({ setCurrentStep, setRegisterForm }) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(registerSchema),
  });

  const onSubmit = async (data) => {
    try {
      const res = await validateEmail({ email: data.email });
      // console.log(res.data.data);

      if (res.data.data.isAlreadyTaken) {
        setError("root", {
          message: res.data.data.message,
        });
        throw Error(res.data.data.message);
      }

      // console.log(data);

      setRegisterForm(data);
      setCurrentStep(3);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="text-start pb-7 border-b">
          <p className="text-2xl font-bold mb-2">Register</p>
          <p>
            Register now to unlock exclusive benefits and personalize your
            experience, making your journey with us even more enjoyable.
          </p>
        </div>
        <div className="xl:w-[400px] mx-auto my-10">
          <h2 className="text-lg font-bold">Create your MealPrep account</h2>
          <div className="form-control w-full my-3">
            <input
              type="email"
              className="border border-[#E3E3E3] rounded-lg text-xs w-full h-[42px] px-4"
              {...register("email")}
              placeholder="Email Address"
            />
            {errors.email && (
              <div className="text-left text-xs text-red-500 my-2">
                {errors.email?.message}
              </div>
            )}
          </div>
          <div>
            <input
              type="password"
              className="border border-[#E3E3E3] rounded-lg text-xs w-full h-[42px] px-4"
              {...register("password")}
              placeholder="Password"
            />
            {errors.password && (
              <div className="text-left text-xs text-red-500 my-2">
                {errors.password?.message}
              </div>
            )}
          </div>
          <p className="text-xs mt-2 text-start">
            Password should be a minimum of 5 characters
          </p>
          <button className="bg-black w-full h-[36px] text-white rounded-[8px] text-xs mt-6">
            Register
          </button>
          {errors.root && (
            <div className="text-left text-xs text-red-500 my-2">
              {errors.root?.message}
            </div>
          )}
          <p className="text-sm text-center mt-3">
            By continuing, you agree to receive marketing emails and our Terms
            and Privacy Policy
          </p>
        </div>
      </form>
    </>
  );
};

export default Register;
