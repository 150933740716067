import React from "react";
import Footer from "../../components/Footer";

const Terms = () => {
  return (
    <div>
      <div className="container mx-auto">
        <p className="mt-5 font-black text-4xl mb-5">TERMS AND CONDITIONS</p>
        <p className="text-left"> 
          <br/>
          <br/><b>1. INFORMATION ABOUT MEALPREP</b><br/> In these terms and
          conditions, the words “we”, “us”, “our” and similar expressions refer
          to Sultan Ahmet Turkish Cuisine Inc., carrying on business under the
          registered name “MealPrep” (hereinafter identified as “MealPrep”), a
          Canadian company, whose head office address is at 5055 Plantation Pl,
          Mississauga, ON L5M 6J3, Canada and that supply to you the products
          (“Products”) listed on our website www.mealprep.sultanahmet.ca or on
          our web applications, mobile applications or via applications on other
          platforms or devices via one of our subscription services
          (“Services”). By using our website www.mealprep.sultanahmet.ca or any
          software application or other platform you download (collectively the
          “Site”), you agree to these Terms and Conditions (the ""Terms and
          Conditions""). These terms and conditions are applicable to persons
          who access or use our Site, purchase Products, or subscribe for our
          Services on the internet or via any web application, mobile
          application, or other platform or device. Please read these terms and
          conditions carefully before accessing and using our Site, ordering any
          Products from us, or subscribing to one of our Services. You should
          understand that by ordering any of our Products or subscribing to one
          of our Services or using our Site, you agree to be bound by these
          Terms and Conditions. You should also carefully review our Privacy
          Policy at www.mealprep.sultanahmet.ca/privacy before placing an order
          for Products or Services or using our Site. You should print a copy of
          these terms and conditions for future reference. Please tick the
          checkbox in the shopping cart to accept these Terms and Conditions to
          confirm your acceptance of these Terms and Conditions. Please
          understand that if you refuse to accept these Terms and Conditions,
          you will not be able to order any Products or Services from us. The
          term “Residents of Ontario” refers to residents of the province of
          Ontario who enter into a contract to which the Consumer Protection Act
          (Ontario) applies. If you elect to receive marketing and support text
          messages from us, either via our website or by sending a text message
          indicating your consent, you are providing (and signing) your prior
          express written consent to receive recurring marketing or promotional
          and support text messages from us (each, a “Text Message”) sent
          through an automatic telephone dialing system. These may include cart
          abandon messages. Message frequency varies. We reserve the right to
          alter the frequency of messages sent at any time, so as to increase or
          decrease the total number of sent messages. This service is optional,
          and your consent is not a condition for purchase. You can opt out of
          receiving any further Text Messages from this program at any time by
          replying “STOP” to any Text Message you receive from us. For help,
          reply “HELP” to any Text Message you receive from us or call +1
          289-805-7003. Your mobile provider’s message and data rates may apply
          to our confirmation Text Message and all subsequent Text Message
          correspondence according to your individual rate plan provided by your
          wireless carrier. Please consult your mobile service carrier’s pricing
          plan to determine the charges for browsing data and sending and
          receiving Text Messages. Under no circumstances will we or our
          affiliates be responsible for any Text messaging or wireless charges
          incurred by you or by a person that has access to your wireless device
          or telephone number. If your carrier does not permit Text messages,
          you may not receive the Text Messages. Neither we nor the wireless
          carriers will be liable for any delays in the receipt of, or failure
          to deliver, any Text Messages, as delivery is subject to effective
          transmission from your network operator. Text Message services are
          provided on an “AS IS” basis. Data obtained from you in connection
          with any Text Message services may include your cell phone number,
          your provider’s name and the date, time and content of your text
          messages. We may use this information in accordance with our Privacy
          Policy to contact you and to provide the services you request from us.
          For more information on how we use telephone numbers, please read our
          Privacy Policy.<br/> 
          
          <br/><b>2. SERVICE AVAILABILITY</b><br/> 
          Our Site, web applications and
          mobile applications are only intended for use by people residing in
          selected parts of Ontario (“Serviced Territory”). We do not accept
          orders from individuals outside the Serviced Territory.<br/> 
          
          <br/><b>3. YOUR STATUS</b><br/>
          By placing an order through our Site, web applications, or mobile
          applications, as applicable, you warrant that: 3.1 You are legally
          capable of entering into binding contracts; 3.2 You are at least 18
          years old; 3.3 You are resident in the Serviced Territory; and 3.4 You
          are accessing our Site, web applications, or mobile applications, as
          applicable, from the Serviced Territory.<br/> 
          
          <br/><b>4. ACCOUNT, ORDERS AND SUBSCRIPTION CONDITIONS</b><br/> 
          By placing an order via our website, web, or
          mobile applications, as applicable, you agree that: 4.1 In order to
          open an account (""Account"") to place orders for Services, you must
          complete the registration process by providing us with current,
          complete and accurate information about yourself, including a
          designated address for delivery of Products (the ""Designated
          Address""). You are responsible for ensuring that your Account
          information, including your Designated Address, is current and
          accurate. You are also responsible for maintaining the confidentiality
          of your Account information, including your password, and for any
          activity that occurs under your Account as a result of your failure to
          keep such information secure and confidential. You agree to notify us
          immediately in writing of any unauthorized use of your Account or
          password, or any other breach of security. 4.2 After placing an order
          through our standard order form on our Site, web applications, or
          mobile applications, as applicable, you will receive an e-mail from us
          acknowledging that we have received your order (Acknowledgement).
          Please note that this does not mean that your order has been accepted.
          Your order constitutes an offer to us to buy Products by subscribing
          to a Service, subject to these Terms and Conditions. All orders are
          subject to acceptance by us, and we will confirm such acceptance to
          you by sending you an e-mail that confirms that your order has been
          accepted (Confirmation). We may choose not to accept any orders in our
          sole discretion. The contract between us (Contract) will only be
          formed when you receive the Confirmation. Note that the
          Acknowledgement and the Confirmation may be contained in the same
          email. 4.3 The Contract will relate only to those Products whose
          dispatch we have confirmed in the Confirmation. We will not be obliged
          to supply any other Products that may have been part of your order
          until the dispatch of such Products has been confirmed in a separate
          Confirmation. 4.4 By accepting the Terms and Conditions and entering
          into a contract with us, you understand that your subscription to the
          Services will be renewed on a weekly basis. When you place an order
          for Services, you will be charged an initial fee, followed by
          recurring weekly fees related to the Services (""Recurring Fee""). By
          subscribing to our Services, you agree to pay these initial and
          recurring fees and authorize us to collect payment for the Services
          using the payment method associated with your Account for an
          indefinite period of time until deactivated by one of us, according to
          the subscription terms set forth in the subscription form. If your
          primary payment method fails, MealPrep may charge a secondary payment
          method associated with your Account. 4.5 The Recurring Fee will
          continue to apply until you deactivate your subscription to the
          Services or change your payment method. To deactivate the Services or
          change your payment method, you must log into your Account on our web
          Site and change your settings or email us at info@sultanahmet.ca with
          your Account details. You may deactivate your subscription to the
          Services at any time and we will not charge you for the next week's
          recurring fees provided you deactivate by 11:59 p.m. EST on Wednesdays
          (the ""Deadline""). However, you will not be eligible for a pro-rated
          refund of the subscription fee paid for the current weekly
          subscription period. If you deactivate your subscription prior to the
          Deadline, you may use your subscription until the end of the current
          weekly subscription period. Your subscription will not be renewed
          after the end of that period. You will not be charged for
          deactivation. 4.6 You may re-subscribe to the Services at any time
          after your cancellation, but we reserve the right to refuse
          re-subscription in our sole discretion. We reserve the right at our
          absolute discretion not to renew your subscription at any time without
          giving any reasons for our decision or, for Ontario residents,
          following a 60-day prior notice. 4.7 Your right to the Services will
          only apply when we receive payment in full of all sums due in respect
          of the Services, including delivery charges, if any.<br/> 
          
          <br/><b>5. DELIVERY, RISK AND TITLE</b><br/> 
          5.1 We will deliver the Products to your Designated Address
          on a specific day of the week as set out in the Confirmation
          (individually, the ""Delivery Date"") in accordance with any special
          instructions you may provide. 5.2 If a Delivery Date falls on a public
          holiday, the Products will be delivered on the next business day or as
          otherwise specified in a notice sent to you via our Site. 5.3 Transfer
          of title and risk occurs when the Products arrive and are delivered at
          your Designated Address. 5.4 To maintain the integrity of Products
          after delivery to your Designated Address, we recommend that you
          immediately refrigerate or freeze perishable items. MealPrep
          recommends that all warming instructions found on the meal packaging
          be followed. Please note that each meal will have a corresponding meal
          description found online.<br/> 
          
          <br/><b>6. HEALTH AND MEDICAL SERVICE DISCLAIMER</b><br/> 
          6.1
          We do not provide professional medical services or advice. MealPrep is
          not a medical organization. The Services provided by the website
          and/or mobile app and/or dietitian do not contain or constitute, and
          should not be interpreted as, medical advice or opinion. No
          doctor-patient and/or therapeutic relationship is created. MealPrep is
          not a licensed medical care provider, and the Services are not
          medically supervised. You are urged and advised to seek the advice of
          a physician before beginning your use of the Services and to get
          periodic medical check-ups as recommended by your primary-care
          physician. Never disregard professional medical advice or delay in
          seeking it because of something you have been told by MealPrep staff
          or any wellness professional. Each time you use the Services, you
          represent to us that you are not using the Services for the purpose of
          seeking medical attention. You further agree that, before using the
          Services, you shall consult your physician, particularly if you are at
          risk for problems resulting from exercise or changes in your diet. If
          any information you receive or obtain from using the Services is
          inconsistent with medical advice from your physician, you should
          follow the advice of your physician.<br/> 
          
          <br/><b>7. VOUCHERS AND GIFT CARDS</b><br/> 
          7.1 We
          may offer gift cards, discount promotions and other types of voucher
          (“Voucher”) which require to be activated by online application in
          order for the holder of the Voucher (“Holder”) to order Products
          through a Service. If paid for, the Voucher is deemed to have been
          sold at the time of payment for it. A Contract, including these Terms
          and Conditions, is created when you receive the Confirmation. 7.2 A
          Voucher may only be used once by its Holder and may not be copied,
          reproduced, distributed or published either directly or indirectly in
          any form or stored in a data retrieval system without our prior
          written approval. 7.3 We reserve the right to withdraw or deactivate
          any Voucher (other than a paid-up gift card) for any reason at any
          time. 7.4 Vouchers may only be redeemed through our Site or web
          applications or mobile applications and not through any other website
          or method of communication. To use your Voucher you will be required
          to enter its unique code at the online checkout and use of such code
          will be deemed to confirm your agreement any special conditions
          attached to the Voucher. 7.5 Any discounts attached to Vouchers apply
          to the price of the Products ordered only and not to delivery charges,
          if any, which will be chargeable at normal rates, unless stated
          otherwise. Discounts do not apply to any separate add-on charges or
          premium charges made through your account. 7.6 With free box vouchers,
          you must have an active subscription on the day of your scheduled
          delivery. We reserve the right to cancel delivery of the Product
          should you deactivate your subscription before the aforementioned
          delivery date.<br/> 
          
          <br/><b>8. PRICE AND PAYMENT</b><br/> 
          8.1 The price of the Products and
          our delivery charges, if any, will be as quoted on our Site, web
          applications or mobile applications. Except for Ontario residents, we
          are not responsible for pricing, typographical, or other errors in any
          offer by us and we reserve the right to cancel any orders arising from
          such errors. 8.2 Product prices include applicable taxes. 8.3. Prices
          for the Services and delivery charges, if any, are subject to
          unilateral change with a minimum of 30-day prior written notice. When
          a price change is introduced for the Services to which you have
          subscribed, you will receive prior written notice from us and will
          have the option to accept the price change and continue your
          subscription to the Services under the new payment terms or deactivate
          your subscription to the Services. For Ontario residents, and where
          otherwise required by applicable law, this 30-day written prior notice
          will also expressly identify the service or fee being changed and its
          prior version and the effective date of the change, and if the change
          results in an increase in your obligations or a decrease in our
          obligations, will provide you with the opportunity to reject the
          change and terminate your Agreement without charge, penalty or
          cancellation fee by sending us written notice no later than 30 days
          after the change becomes effective. 8.4 Payment for all Products and
          Services must be by credit card or PayPal. We accept payment with
          American Express, Visa, Mastercard, and via PayPal. We reserve the
          right to change the payment methods we accept at any time. We will not
          charge your credit card or PayPal account for a weekly delivery until
          after the deactivation deadline for such weekly delivery, with the
          exception of your first delivery under one of our Services which may
          occur immediately following your initial subscription to said Service.
          8.5 You represent and warrant that (i) the payment information you
          supply to us is true, correct and complete, (ii) you are duly
          authorized to use such credit card or PayPal account for your
          purchase, and (iii) you will pay charges incurred by you at the posted
          prices, including all applicable taxes, if any. 8.6 If a payment is
          not successfully paid and you do not edit your payment method or
          cancel your purchase of a Product, you remain responsible for any
          uncollected amounts and authorize us to continue billing the payment
          method, as it may be updated. MealPrep reserves the right to collect
          any outstanding payment due, and may transfer the collection of your
          outstanding balance to a third party collection agency.<br/> 
          
          <br/><b>9. OUR REFUNDS POLICY</b><br/> 
          9.1 If you refuse a Product from us: 9.1.1 Because you have
          deactivated your subscription to the Services before the Deadline (see
          clause 4.5 above), we will process the refund due to you as soon as
          possible and, in any case, within 30 days of the day you gave notice
          of deactivation or, in the case of Ontario residents, within 15 days
          of the cancellation of your subscription. In this case, we will refund
          the price of the Product in full, and any applicable delivery charges.
          9.1.2 For any other reason, we will notify you of your refund, if any,
          via phone or e-mail within a reasonable period of time. We will
          usually process the refund due to you as soon as possible and, in any
          case, within 30 days of the day we confirmed to you via phone or
          e-mail that you were entitled to a refund. If all or part of a Product
          (Defective Item) is defective, we will credit you an amount equivalent
          to the value of the Defective Item towards a future purchase,
          including any applicable delivery charges and any reasonable costs you
          incur in refusing the Defective Item. 9.2 We will usually refund any
          money received from you using the same method originally used by you
          to pay for your purchase.<br/> 
          
          <br/><b>10. VIOLATION OF THESE TERMS AND CONDITIONS</b><br/>
          10.1 We may terminate and/or suspend your Account immediately, without
          notice, if there has been a breach of these Terms and Conditions or
          any agreement, policies and conditions posted on the Site by you or
          anyone using your credentials. We may also cancel or suspend your
          Account or Services for any other reason, including inactivity for an
          extended period of time, but we will attempt to notify you in advance
          of such cancellation or suspension and for Ontario residents, at least
          60 days prior to the date of such cancellation. Except for Ontario
          residents, we shall not be liable to you or any third party for any
          termination of your access to your Account or the Site and/or
          Services. If applicable, you agree not to attempt to use your Account
          and/or the Services after such deletion, deactivation or termination.
          You also agree that any breach by you of the Contract between you and
          us will constitute an unlawful and unfair business practice and will
          cause irreparable harm to us, for which monetary damages would be
          insufficient, and you consent to us obtaining any injunctive or
          equitable relief that we deem necessary or appropriate in such
          circumstances. These remedies are in addition to any other remedies we
          may have at law or in equity. 10.2 Except for residents of Ontario, we
          may, in our sole discretion and without prior notice, terminate your
          access to the Services for cause, including, but not limited to (1)
          requests by law enforcement or other government agencies, (2)
          discontinuance or material modification of the Services offered on or
          through the Site, or (3) unexpected technical problems or
          difficulties.<br/> 
          
          <br/><b>11. TRANSFER OF RIGHTS AND OBLIGATIONS</b><br/> 
          11.1 The Contract
          between you and us is binding on you and us and on our respective
          successors and assignees. 11.2 You may not transfer, assign, charge or
          otherwise dispose of a Contract, or any of your rights or obligations
          arising under it, without our prior written consent. 11.3 We may
          transfer, assign, charge, sub-contract or otherwise dispose of a
          Contract, or any of our rights or obligations arising under it, at any
          time during the term of the Contract. 11.4 These Terms and Conditions
          do not and are not intended to confer any rights or remedies upon any
          person other than you.<br/> 
          
          <br/><b>12. INTELLECTUAL PROPERTY RIGHTS</b><br/> 
          12.1 We are
          the owner or licensee of all intellectual property rights in our Site,
          whether registered or unregistered, and in the material published on
          it, including all text, graphics, user interfaces, visual interfaces,
          photographs, trademarks, logos, sounds, music, artwork and computer
          code (collectively, ""Content""), including but not limited to the
          design, structure, selection, coordination, expression, ""look and
          feel"" and layout of such Content. The Site and the Content are
          protected by copyright and trademark laws and may not be copied,
          reproduced, republished, uploaded, published, posted, publicly
          displayed, encoded, translated, transmitted or distributed in any way
          (including by ""mirroring"") to any other computer, server, software
          or other medium for publication or distribution or for any commercial
          enterprise, without our express prior written consent. 12.2 Despite
          article 11.1, you may print off one copy, and may download extracts,
          of any pages from our Site, web applications and mobile applications
          for your personal reference. You must not use any part of our
          copyright materials for commercial purposes without first obtaining a
          license to do so from us and our licensors. 12.3 If you post comments
          or reviews on the Products or Services to any website, blog or social
          media network (Commentary) you must ensure that such Commentary
          represents your fairly-held opinions. By subscribing to the Services
          you irrevocably authorize us to quote from or repost your Commentary
          on our Site, web applications and mobile applications and in any
          advertising or social media outlets that we may create or contribute
          to.<br/> 
          
          <br/><b>13. USER CONTENT</b><br/> 
          13.1 Our Site or any social media platform on
          which we have an official page or news feed, may include, now or in
          the future, areas (""Interactive Areas"") that allow users to post
          content, including, but not limited to, recipes, reviews, photos,
          videos, music, audio, text, graphics, code or other materials (""User
          Content""). Any User Content you post must, in its entirety, comply
          with all applicable laws and regulations and these Terms and
          Conditions. 13.2 You understand and acknowledge that you are
          responsible for all User Content that you submit or contribute to and
          for your use of the Interactive Areas of the Site and that you alone
          are solely responsible for such content and use, including its
          legality, reliability, accuracy and appropriateness. We are not
          responsible for the content or accuracy of any User Content posted by
          you or any other user of the Site. You understand and acknowledge that
          User Content that you share with a third party via the Site or third
          party platforms will be viewable by others in accordance with the
          privacy settings you select. 13.3 Any User Content you post on the
          Site will be considered non-confidential and non-proprietary. By
          providing any User Content on the Site, you represent and warrant
          that: you own or control all rights to the User Content and have the
          right to grant the license to us, our affiliates and service
          providers, and their respective licensees, successors and assigns; you
          grant us, our affiliates and service providers, and their respective
          licensees, successors and assigns the right to use, reproduce, modify,
          perform, display, distribute and disclose to third parties any such
          material for any purpose; and all of your User Content complies and
          will comply with these Terms and Conditions. 13.4 By using the
          Interactive Areas of the Site, you also agree not to create, post,
          share or store the following: (1) Any content that would expressly or
          impliedly encourage conduct that would be considered a violation of
          any law or regulation; (2) Any content that we deem to be indecent,
          obscene, abusive, threatening, pornographic, or harassing; (3) Any
          content that violates the proprietary rights of any third party; (4)
          Any content that contains confidential information; (5) Any content
          that imitates or misrepresents your affiliation with any person or
          entity without their express permission; (6) Any content that contains
          solicitations, including, but not limited to, advertisements,
          promotional materials (other than those directly related to MealPrep)
          and political campaign requests; (7) Any content that, in our sole
          judgment, is objectionable or restricts or inhibits any other person
          from using or enjoying the Site or the Services, or that may expose us
          or others to any damages or liability of any kind. 13.5 Although we
          are not required to regulate it, we reserve the right, in our sole
          discretion, to monitor, edit or delete User Content posted or stored
          on the Site for any reason. You are solely responsible for creating
          backup copies and replacing any User Content that you post or store on
          the Site at your sole cost and expense. 13.6 We do not claim any
          ownership rights in your User Content. However, by uploading, posting,
          or submitting User Content on the Site or news feeds on third-party
          social media platforms (e.g., our Facebook page, Instagram page, or
          Twitter feed), you hereby agree to: (1) to grant us and our affiliates
          and subsidiaries a non-exclusive, royalty-free, transferable,
          worldwide, perpetual, irrevocable, and fully sublicensable right and
          license to use, reproduce, modify, adapt, publish, translate, create
          derivative works from, distribute, perform, and publicly display your
          User Content in whole or in part, and your name, likeness, voice and
          image in any manner or for any reason and for any purpose whatsoever,
          in our sole discretion, including, without limitation, for
          advertising, promotional, publicity, commercial, illustrative,
          artistic and other commercial and non-commercial purposes ; and (2)
          represent and warrant that (a) you own and control all rights to the
          User Content or have the legal right to display such User Content, (b)
          the User Content is non-confidential and you authorize us to use such
          User Content for the purposes described in these Terms and Conditions
          (c) the User Content is accurate and not misleading or harmful in any
          way, and (d) the User Content, and its use and display, does not and
          will not violate these Terms and Conditions or any applicable law,
          rule, regulation or third party right. You agree to (i) waive all
          moral rights in your User Content in favor of us; and (ii) release and
          hold us and our agents, employees, directors, officers, successors and
          assigns harmless from any claims based on publicity rights,
          defamation, invasion of privacy, copyright infringement, trademark
          infringement, or any other intellectual property-related cause of
          action that relate in any way to the User Content. For greater
          certainty and for the avoidance of doubt, we reserve the right, in our
          sole and absolute discretion and at any time, to modify or edit the
          User Content if a complaint is received with respect to the User
          Content, or for any other reason. If such action is necessary at any
          time, we reserve the right, in our sole and absolute discretion, to
          remove the User Content.<br/> 
          
          <br/><b>14. EVENTS OUTSIDE OUR CONTROL</b><br/> 
          14.1 We will
          not be liable or responsible for any failure to perform, or delay in
          performance of, any of our obligations under a Contract that is caused
          by events outside our reasonable control (“Force Majeure Event”). 14.2
          A Force Majeure Event includes any act, event, non-happening, omission
          or accident beyond our reasonable control and includes in particular
          (without limitation) the following: 14.2.1 Strikes, lock-outs or other
          industrial action; 14.2.2 Civil commotion, riot, invasion, terrorist
          attack or threat of terrorist attack, war (whether declared or not) or
          threat or preparation for war; 14.2.3 Fire, explosion, storm, flood,
          earthquake, subsidence, epidemic or other natural disaster; 14.2.4
          Impossibility of the use of railways, shipping, aircraft, motor
          transport or other means of public or private transport; 14.2.5
          Impossibility of the use of public or private telecommunications
          networks; and 14.2.6 The acts, decrees, legislation, regulations, or
          restrictions of any government. 14.3 Our performance under any
          Contract is deemed to be suspended for the period that the Force
          Majeure Event continues, and we will have an extension of time for
          performance for the duration of that period. We will use our
          reasonable endeavors to bring the Force Majeure Event to a close or to
          find a solution by which our obligations under the Contract may be
          performed despite the Force Majeure Event. 14.4 Availability and
          Delivery. Your order will be fulfilled by the delivery date set out in
          the Confirmation or, if no delivery date is specified, then within 30
          days of the date of the Confirmation, absent the occurrence of a Force
          Majeure Event. In the event of a Force Majeure Event, we are not
          liable for the cost of any compromised or failed deliveries; however,
          we reserve the right to refund you for all or part of any such
          compromised or failed deliveries as we see fit.<br/> 
          
          <br/><b>15. LIMITED WARRANTY AND DISCLAIMER</b><br/> 
          15.1 We warrant to you that any Product purchased from
          us through our Site, web applications or mobile applications will, on
          delivery, conform with its description, be of satisfactory quality,
          and be reasonably fit for all the purposes for which products of that
          kind are commonly supplied. 15.2 Our liability for defective Products
          is limited to replacement, performance, refund or credit, as
          applicable. 15.3 Except for Ontario residents, while we do our best to
          ensure that the information presented to you on the Site is accurate,
          we make no warranties, representations, promises, or undertakings,
          express or implied, as to the accuracy of any information provided on
          or through the Site. If you choose to rely on any information provided
          on or through our Site, you do so at your own risk. 15.4 WE DO NOT
          PROMISE THAT THE SITE OR THE SERVICES WILL BE ERROR-FREE OR
          UNINTERRUPTED, OR THAT ANY DEFECTS WILL BE CORRECTED. ALL INFORMATION
          PROVIDED ON THE SITE IS SUBJECT TO CHANGE WITHOUT NOTICE. WE CANNOT
          GUARANTEE THAT FILES OR OTHER DATA YOU ACCESS OR DOWNLOAD FROM OR
          THROUGH THE SITE WILL BE FREE OF VIRUSES, CONTAMINATION OR DESTRUCTIVE
          ELEMENTS. EXCEPT FOR ONTARIO RESIDENTS, EXCEPT FOR THE LIMITED
          WARRANTY SET FORTH ABOVE, WE DISCLAIM ALL WARRANTIES, EXPRESS OR
          IMPLIED, INCLUDING WARRANTIES OF ACCURACY, NON-INFRINGEMENT,
          MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. EXCEPT FOR
          RESIDENTS OF ONTARIO, WE DISCLAIM ALL LIABILITY FOR THE ACTS,
          OMISSIONS AND CONDUCT OF ANY THIRD PARTY IN CONNECTION WITH YOUR USE
          OF THE SITE AND/OR ANY PRODUCTS OR SERVICES. YOU ASSUME FULL
          RESPONSIBILITY FOR YOUR USE OF THE SITE AND ANY LINKED SITES. EXCEPT
          FOR ONTARIO RESIDENTS, YOUR SOLE REMEDY AGAINST US FOR DISSATISFACTION
          WITH THE SITE, PRODUCTS OR SERVICES OR ANY CONTENT IS TO DISCONTINUE
          USING THE SITE OR SERVICES. THIS LIMITATION ON REMEDIES FORMS PART OF
          THE AGREEMENT BETWEEN THE PARTIES. Except for Ontario residents, the
          above disclaimer applies to any damages, liability, or injury caused
          by any failure, error, omission, interruption, deletion, defect, delay
          in operation or transmission, computer virus, communication line
          failure, theft or destruction, or unauthorized access to, alteration
          of, or use of the Site, whether for breach of contract, tort,
          negligence or any other cause of action.<br/> 
          
          <br/><b>16. LIABILITY AND INDEMNIFICATION</b><br/> 16.1 Except for residents of Ontario, in no case shall
          we be liable for any direct, indirect, incidental, special,
          consequential, exemplary, or other damages, including, but not limited
          to, damages resulting from (i) your use of or inability to use the
          Products or Services, (ii) errors, mistakes or inaccuracies in the
          content of the Site, (iii) bodily injury (including, but not limited
          to, allergic reactions to Products received through the Services) or
          property damage of any kind arising out of or in connection with your
          use of the Products or Services, any bugs, viruses, Trojan horses or
          other files or data which may be harmful to computer or communications
          equipment or data which may have been transmitted to or through the
          Site, or (iv) any errors or omissions in any material on the Site or
          any other loss or damage of any kind arising out of or in connection
          with your use of the Products or Services. The foregoing limitations
          apply to the greatest extent permitted by law in the applicable
          jurisdiction. Except for residents of Ontario, notwithstanding any
          other provision herein, if we fail to comply with this Agreement, we
          will only be liable to you for the purchase price of the Services.
          16.2 You agree that it is your sole responsibility to ensure that any
          Products you obtain through the Services are suitable for you and that
          you do not order Products to which you may be allergic. 16.3 Nothing
          in this agreement excludes or limits our liability for any matter for
          which it would be illegal for us to exclude or attempt to exclude our
          liability. 16.4 You agree to defend, indemnify and hold harmless us,
          our affiliates and licensors and their respective affiliates, and each
          of their directors, officers, agents, contractors, partners,
          representatives and employees, from and against any loss, liability,
          threatened or actual claim, demand, damages, costs and expenses,
          including reasonable legal fees, arising out of or in connection with
          your breach of these terms and conditions or your violation of any law
          or the rights of any third party with respect to the Site, our web and
          mobile applications, the Products or the Services. We reserve the
          right, at our own expense, to assume the exclusive defence and control
          of any matter otherwise subject to indemnification by you here-under,
          and you shall cooperate as fully as reasonably required by us. <br/>
          
          <br/><b>17. CHANGES</b><br/>
           17.1 We reserve the right to do the following, at any time and
          without notice: (1) modify, suspend or terminate the operation of the
          Site or any portion of the Site, for any reason; (2) modify or change
          the Site or any portion of the Site, and any applicable policies or
          terms; and (3) interrupt the operation of the Site or any portion of
          the Site, as necessary, to perform routine or non-routine maintenance,
          error correction or other modifications 17.2 These terms and
          conditions do not and are not intended to confer any rights or
          remedies upon any person other than you. 
          
          <br/><b>18. WRITTEN COMMUNICATIONS</b><br/> By
          submitting your email to MealPrep you are indicating an interest in
          MealPrep and may receive email marketing communications. Applicable
          laws require that some of the information or communications we send to
          you should be in writing. When using our Site, web applications, or
          mobile applications you accept that communication with us will be
          mainly electronic. We will contact you by e-mail or provide you with
          information by posting notices on our website or via our web
          applications or mobile applications in our sole discretion. For
          contractual purposes, you agree to this electronic means of
          communication and you acknowledge that all contracts, notices,
          information and other communications that we provide to you
          electronically comply with any legal requirement that such
          communications be in writing. This condition does not affect your
          statutory rights.<br/> 
          
          <br/><b>19. NOTICES</b><br/> All notices given by you to us must be
          given to Sultan Ahmet Turkish Cuisine Inc. at info@sultanahmet.ca. We
          may give notice to you at either the e-mail or postal address you
          provide to us when placing an order, or in any of the ways specified
          in clause 17 above. Notice will be deemed received and properly served
          immediately when posted on our Site, 24 hours after an e-mail is sent,
          or on the date of posting of any letter. In proving the service of any
          notice, it will be sufficient to prove, in the case of a letter, that
          such letter was properly addressed, stamped and placed in the post
          and, in the case of an e-mail, that such e-mail was sent to the
          specified e-mail address of the addressee.<br/> 
          
          <br/><b>20. WAIVER</b><br/> 20.1 If we fail,
          at any time during the term of a Contract, to insist upon strict
          performance of any of your obligations under the Contract or any of
          these Terms and Conditions, or if we fail to exercise any of the
          rights or remedies to which we are entitled under the Contract, this
          will not constitute a waiver of such rights or remedies and will not
          relieve you from compliance with such obligations. 20.2 A waiver by us
          of any default will not constitute a waiver of any subsequent default.
          20.3 No waiver by us of any of these Terms and Conditions will be
          effective unless it is expressly stated to be a waiver and is
          communicated to you in writing in accordance with clause 17 above.<br/> 
          
          <br/><b>21. SEVERABILITY</b><br/> If any of these Terms and Conditions or any provisions of
          a Contract are determined by any competent authority to be invalid,
          unlawful, or unenforceable to any extent, such term, condition, or
          provision will to that extent be severed from the remaining terms,
          conditions and provisions and will not impact the validity or
          enforceability of these remaining terms, conditions and provisions,
          which will continue to be valid to the fullest extent permitted by
          law.<br/> 
          
          <br/><b>22. ENTIRE AGREEMENT</b><br/> 22.1 These Terms and Conditions, any
          document expressly referred to in them and any Contract constitute the
          whole agreement between us and supersede all previous discussions,
          correspondence, negotiations, previous arrangement, understanding or
          agreement between us relating to the subject matter of any Contract.
          22.2 We each acknowledge that, in entering into a Contract, neither of
          us relies on any representation or warranty (whether made innocently
          or negligently) that is not set out in these Terms and Conditions or
          the documents referred to in them. 22.3 Except for Ontario residents,
          each of us agrees that our only liability in respect of those
          representations and warranties that are set out in these Terms and
          Conditions or the documents referred to in them (whether made
          innocently or negligently) will be for breach of contract. 22.4
          Nothing in this clause limits or excludes any liability for fraud.<br/> 

          <br/><b>23. OUR RIGHT TO VARY THESE TERMS AND CONDITIONS</b><br/> 
          23.1 We have the right to
          revise and amend these Terms and Conditions from time to time for any
          reason including to reflect changes in market conditions affecting our
          business, changes in technology, changes in payment methods, changes
          in relevant laws and regulatory requirements and changes in our
          system's capabilities. 23.2 You will be subject to the policies and
          Terms and Conditions in force at the time that you order Products and
          subscribe to Services from us, unless any change to those policies or
          these terms and conditions is required to be made by law or
          governmental authority (in which case it will apply to orders
          previously placed by you), or if we notify you of the change to those
          policies or these Terms and Conditions before we send you the
          Confirmation (in which case we have the right to assume that you have
          accepted the change to the terms and conditions, unless you notify us
          to the contrary within seven working days of receipt by you of the
          Products).<br/> 
          
          <br/><b>24. APPLICABLE LAW AND JURISDICTION</b><br/> 
          24.1 Except for Ontario
          residents, Contracts for the purchase of Services or Products from us
          and (i) any dispute or claim arising out of or in connection with
          these Contracts or their subject matter or formation (including
          non-contractual disputes or claims) or (ii) arising out of or in
          connection with our Site (including non-contractual disputes or
          claims) will be governed by Ontario law and the federal laws of Canada
          applicable therein. 24.2 Except for Ontario residents, any dispute or
          claim arising out of or in connection with such Services, Products, or
          Site (including non-contractual disputes or claims) will be subject to
          the exclusive jurisdiction of the courts of Ontario. Except for
          Ontario residents, you expressly agree: (i) to submit to the exclusive
          personal jurisdiction of the courts of Ontario; and (ii) that the
          Products and the Services shall be deemed passive that do not give
          rise to personal jurisdiction over MealPrep, either specific or
          general, in jurisdictions other than Ontario. CONTACT US For general
          questions, questions concerning your billing, or any issue with your
          last order, write to info@sultanahmet.ca.<br/><br/><br/>
        </p>
      </div>
      <Footer/>
    </div>
  );
};
export default Terms;
